import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useLayoutEffect,
} from "react";
// { useEffect }

import styles from "./Header.module.scss";
import classNames from "classnames/bind";
import Tooltip from "../atoms/Tooltip";
import List from "../atoms/List";
import Card from "../atoms/Card";
import Divider from "../atoms/Divider";
import Input from "../component/Input";
import SummaryCard from "../component/SummaryCard";
import DataCard from "../component/DataCard";
import Button from "../atoms/Button";
import NavButton from "../component/NavButton";
import useGlobalVar from "../hooks/useGlobalVar";
import { formatData, formatUnit } from "../util/formatData";
import useGlobalData from "../hooks/useGlobalData";
import useButtonGesture from "../hooks/useButtonGesture";
import { ReactComponent as NumberpadChangeUnit } from "../svgs/NumberpadChangeUnit.svg";
import { Link } from "react-router-dom";
import Popup from "../component/Popup";
import { motion } from "framer-motion";
import axios from "axios";
import { API_URI, SURVEY_PATH } from "../shortcut";
import AutoFocusTextarea from "../component/AutoFocusTextarea";
import useQueryParam from "../hooks/useQueryParam";
import { ipad_width } from "../util/style";
import { ReactComponent as IconMenu } from "../svgs/IconMenu.svg";
import { ReactComponent as IconClose } from "../svgs/IconClose.svg";
import CustomLink from "../component/CustomLink";

const cx = classNames.bind(styles);

const Header = ({
  emph,
  close = false,
  onClick = (e) => {
    console.log("clicked header with data");
    console.log(e);
  },
}) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const page_list = [
    { kor: "건축자재", eng: "material" },
    { kor: "브랜드", eng: "brand" },
    { kor: "지도", eng: "map" },
  ];
  const my_page_list = [
    { kor: "머티리얼보드", eng: "materialboard" },
    { kor: "견적요청", eng: "RAQ" },
  ];
  const [open_mobile_menu, setOpenMobileMenu] = useState(false);

  const send_title_ref = useRef();
  const send_content_ref = useRef();

  const query = useQueryParam();

  const handleClosePopup = () => {
    if (global_data.popup_data?.id === "send") {
      setGlobalData({
        send_title: send_title_ref.current?.value,
        send_content: send_content_ref.current?.value,
      });
    }
    setGlobalData({ popup_data: null }); // 팝업 닫기 후 상태 초기화
  };

  useEffect(() => {
    if (global_var.id) {
      if (
        !global_var.already_alerted_survey_button &&
        query.get("from") !== "survey"
      ) {
        setGlobalData({ popup_data: { id: "click_survey_button" } });
      } else if (!global_var.already_alerted_send_button) {
        setGlobalData({ popup_data: { id: "click_send_button" } });
      }
    }
  }, [global_var.id]);

  return (
    <div
      className={cx(
        "wrapper",
        close && global_data.popup_data?.id !== "click_send_button"
          ? "close"
          : ""
      )}
    >
      {global_var.window_width >= ipad_width ? (
        <div className={cx("frame-header")}>
          <List type={"row"} attach={"space"} align={"center"}>
            <List type={"row"} align={"center"}>
              <Link to={"/"}>
                <div className={"GTM-Logo " + cx("frame-logo")}>
                  머티리얼플랫폼
                </div>
              </Link>
            </List>
            <div className={cx("frame-my")}>
              <List type={"row"} attach={"end"}>
                <List type={"row"} height={3} gap={0.375}>
                  {global_var.window_width < 1000 &&
                    page_list.map((e, idx) => {
                      return (
                        <Link to={"/" + e.eng} key={e + idx}>
                          <Button
                            color={"nav" + (e.eng === emph ? "-emph" : "")}
                            GTM={"Header_" + e.kor.replaceAll(" ", "_")}
                          >
                            {e.kor}
                          </Button>
                        </Link>
                      );
                    })}
                  <Button
                    color={"red"}
                    style={{ overflow: "visible" }}
                    onClick={() =>
                      setGlobalData({ popup_data: { id: "send" } })
                    }
                    GTM="Header_의견 보내기"
                  >
                    의견 보내기
                    {global_data.popup_data?.id === "click_send_button" && (
                      <motion.div
                        initial={{
                          x: "-50%",
                          y: "-50%",
                          opacity: 0,
                          scale: 0.8,
                        }}
                        animate={{
                          x: "-50%",
                          y: "-50%",
                          opacity: 0.3,
                          scale: 1,
                        }}
                        transition={{
                          repeat: Infinity,
                          repeatType: "reverse",
                          type: "spring",
                          delay: 0.6,
                        }}
                        className={cx("intro")}
                      />
                    )}
                  </Button>
                  {my_page_list.map((e, idx) => {
                    return (
                      <Link to={"/" + e.eng} key={e + idx}>
                        <Button
                          color={"nav" + (e.eng === emph ? "-emph" : "")}
                          GTM={"Header_" + e.kor.replaceAll(" ", "_")}
                        >
                          {e.kor}
                        </Button>
                      </Link>
                    );
                  })}
                </List>
              </List>
            </div>
          </List>
          {global_var.window_width >= 1000 && (
            <div className={cx("frame-nav-overlay")}>
              <List>
                <div className={cx("frame-nav")}>
                  <List type={"row"} height={3} gap={0.375}>
                    {page_list.map((e, idx) => {
                      return (
                        <Link to={"/" + e.eng} key={e + idx}>
                          <Button
                            color={"nav" + (e.eng === emph ? "-emph" : "")}
                            GTM={"Header_" + e.kor.replaceAll(" ", "_")}
                          >
                            {e.kor}
                          </Button>
                        </Link>
                      );
                    })}
                  </List>
                </div>
              </List>
            </div>
          )}
        </div>
      ) : (
        <motion.div
          className={cx("frame-fixed")}
          initial={{ minHeight: "0%" }}
          animate={{
            minHeight:
              !(close && global_data.popup_data?.id !== "click_send_button") &&
              open_mobile_menu
                ? "var(--100vh)"
                : "0%",
            backgroundColor:
              !(close && global_data.popup_data?.id !== "click_send_button") &&
              open_mobile_menu
                ? "#ffffff"
                : "#ffffff55",
          }}
        >
          <List tight={false}>
            <List type={"row"} attach={"space"} align={"center"} tight={false}>
              <Link to={"/"}>
                <div className={"GTM-Logo " + cx("frame-logo")}>
                  머티리얼플랫폼
                </div>
              </Link>
              <div
                className={cx("frame-menu")}
                onClick={() => {
                  setOpenMobileMenu((e) => !e);
                }}
              >
                {open_mobile_menu ? <IconClose /> : <IconMenu />}
              </div>
            </List>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity:
                  !(
                    close && global_data.popup_data?.id !== "click_send_button"
                  ) && open_mobile_menu
                    ? 1
                    : 0,
              }}
              style={{ width: "calc(100% - 1rem)", height: "80%" }}
            >
              <List height={3} attach="center" gap={1} tight={false}>
                {[...page_list, ...my_page_list].map((e, idx) => {
                  return (
                    <CustomLink
                      to={"/" + e.eng}
                      key={e + idx}
                      style={{
                        width: "100%",
                        pointerEvents:
                          !(
                            close &&
                            global_data.popup_data?.id !== "click_send_button"
                          ) && open_mobile_menu
                            ? "auto"
                            : "none",
                      }}
                    >
                      <Button
                        color={"nav" + (e.eng === emph ? "-emph" : "")}
                        fillX
                        style={{ pointerEvents: "inherit" }}
                        GTM={"Header_" + e.kor.replaceAll(" ", "_")}
                      >
                        {e.kor}
                      </Button>
                    </CustomLink>
                  );
                })}
                <Button
                  color={"red"}
                  style={{ overflow: "visible" }}
                  onClick={() => setGlobalData({ popup_data: { id: "send" } })}
                  fillX
                  GTM="Header_의견_보내기"
                >
                  의견 보내기
                  {global_data.popup_data?.id === "click_send_button" && (
                    <motion.div
                      initial={{
                        x: "-50%",
                        y: "-50%",
                        opacity: 0,
                        scale: 0.8,
                      }}
                      animate={{
                        x: "-50%",
                        y: "-50%",
                        opacity: 0.3,
                        scale: 1,
                      }}
                      transition={{
                        repeat: Infinity,
                        repeatType: "reverse",
                        type: "spring",
                        delay: 0.6,
                      }}
                      className={cx("intro")}
                    />
                  )}
                </Button>
              </List>
            </motion.div>
          </List>
        </motion.div>
      )}
      <Popup
        change_trigger={global_data.popup_data}
        use_backdrop
        onClose={handleClosePopup}
      >
        {global_data.popup_data?.id == "send" ? (
          <div key="send" className={cx("frame-popup")}>
            <List align="center" gap={1} fillX>
              <div className={cx("text-popup-title")}>의견 보내기</div>
              <div className={cx("text-popup")}>
                서비스를 사용하시며 느꼈던 불편한 점이나, 원하시는 부분을 편하게
                보내주세요!
              </div>
              <List align="center" gap={0.5} fillX>
                <AutoFocusTextarea
                  className={cx("frame-popup-textarea")}
                  rows="1"
                  placeholder="제목을 입력해주세요."
                  ref={send_title_ref}
                  defaultValue={global_data.send_title}
                ></AutoFocusTextarea>
                <textarea
                  className={cx("frame-popup-textarea")}
                  rows="10"
                  placeholder="의견을 입력해주세요."
                  ref={send_content_ref}
                  defaultValue={global_data.send_content}
                ></textarea>
                <List attach="center" type="row" fillX gap={0.5} multiple_line>
                  <Button
                    type="green-light"
                    onClick={() => {
                      setGlobalData({
                        send_title: send_title_ref.current?.value,
                        send_content: send_content_ref.current?.value,
                      });
                      setGlobalData({ popup_data: null });
                    }}
                  >
                    닫기
                  </Button>
                  <Button
                    type="brown-light"
                    onClick={() => {
                      if (
                        (send_title_ref.current.value?.replaceAll(" ", "")
                          ?.length ?? 0) < 1 &&
                        (send_content_ref.current.value?.replaceAll(" ", "")
                          ?.length ?? 0) < 1
                      ) {
                        setGlobalData({ popup_data: { id: "send_nothing" } });
                        setTimeout(
                          () => setGlobalData({ popup_data: { id: "send" } }),
                          1500
                        );
                      } else {
                        const currentUrl = window.location.href;
                        axios
                          .post(API_URI + "api/saveOpinionData", {
                            sendId: global_var.id,
                            sendTitle: send_title_ref.current.value,
                            sendContent: send_content_ref.current.value,
                            sendEmail: global_var.my_email,
                            currentUrl: currentUrl,
                          })
                          .then(() => {
                            setGlobalData({
                              send_title: undefined,
                              send_content: undefined,
                            });
                            setGlobalData({
                              popup_data: { id: "send_success" },
                            });
                            setTimeout(
                              () => setGlobalData({ popup_data: null }),
                              1200
                            );
                          })
                          .catch((e) => {
                            console.log(e);
                            setGlobalData({
                              send_title: send_title_ref.current?.value,
                              send_content: send_content_ref.current?.value,
                            });
                            setGlobalData({ popup_data: { id: "send_error" } });
                            setTimeout(
                              () =>
                                setGlobalData({ popup_data: { id: "send" } }),
                              1200
                            );
                          });
                      }
                    }}
                  >
                    의견 보내기
                  </Button>
                </List>
              </List>
            </List>
          </div>
        ) : global_data.popup_data?.id == "send_success" ? (
          <div key="send_success" className={cx("frame-popup")}>
            <div className={cx("text-popup")}>의견을 성공적으로 보냈어요!</div>
          </div>
        ) : global_data.popup_data?.id == "send_error" ? (
          <div key="send_error" className={cx("frame-popup")}>
            <div className={cx("text-popup")}>
              의견을 보내는 데 문제가 생겼어요. 다시 시도해보세요.
            </div>
          </div>
        ) : global_data.popup_data?.id == "send_nothing" ? (
          <div key="send_error" className={cx("frame-popup")}>
            <div className={cx("text-popup")}>
              제목 또는 의견 중 하나 이상 작성 부탁드려요.
            </div>
          </div>
        ) : undefined}
      </Popup>
      {global_var.media_mobile ? null : (
        <Popup change_trigger={global_data.popup_data} use_backdrop>
          {global_data.popup_data?.id === "click_survey_button" ? (
            <div className={cx("frame-popup")}>
              <List align="center" gap={2}>
                <List align="center" gap={1}>
                  <div className={cx("text-popup-title")}>
                    베타 서비스에 오신 것을 환영합니다!
                  </div>
                  <List align="center" fillX>
                    <div className={cx("text-popup")}>
                      사용자분들의 의견을 듣고 더욱 나은 서비스를 만드려고
                      노력하고 있어요.
                    </div>
                    <div className={cx("text-popup")}>
                      서비스를 사용해보시기 전에
                    </div>
                    <div className={cx("text-popup")}>
                      먼저 <strong>설문조사</strong>를 잠깐 부탁드려도 될까요?
                    </div>
                    <div className={cx("text-popup")}>
                      <strong>
                        매월 10분을 추첨하여 스타벅스 상품권을 드려요!
                      </strong>
                    </div>
                    <div className={cx("text-popup")}>(24년 7~10월 진행)</div>
                  </List>
                </List>
                <List attach="center" type="row" fillX gap={0.5} multiple_line>
                  <Button
                    type="green-light"
                    onClick={() => {
                      setGlobalVar({ already_alerted_survey_button: true });
                      setGlobalData({ popup_data: null });
                      setTimeout(
                        () =>
                          setGlobalData({
                            popup_data: { id: "click_send_button" },
                          }),
                        1500
                      );
                    }}
                  >
                    나중에 할게요.
                  </Button>
                  <Button
                    type="brown-light"
                    onClick={() => {
                      setGlobalVar({ already_alerted_survey_button: true });
                      setGlobalData({ popup_data: null });
                      setTimeout(
                        () =>
                          setGlobalData({
                            popup_data: { id: "click_send_button" },
                          }),
                        1500
                      );
                      window.open(SURVEY_PATH, "설문조사");
                    }}
                  >
                    설문조사하러 가기
                  </Button>
                </List>
              </List>
            </div>
          ) : global_data.popup_data?.id === "click_send_button" ? (
            <div className={cx("frame-popup")}>
              <List align="center" gap={2}>
                <List align="center" gap={1}>
                  <div className={cx("text-popup-title")}>
                    의견을 보내주세요.
                  </div>
                  <List align="center" fillX>
                    <div className={cx("text-popup")}>
                      현재 베타 서비스 단계로 아직 서비스 개선 중에 있어요.
                    </div>
                    <div className={cx("text-popup")}>
                      서비스를 사용해 보시고 불편하거나 원하는 부분이 있다면
                    </div>
                    <div className={cx("text-popup")}>
                      위에 있는 [의견 보내기] 버튼을 클릭해서 언제든지 의견을
                      보내주세요!
                    </div>
                  </List>
                </List>
                <List attach="center" type="row" fillX gap={0.5} multiple_line>
                  <Button
                    type="green-light"
                    onClick={() => {
                      setGlobalVar({ already_alerted_send_button: true });
                      setGlobalData({ popup_data: { id: "send" } });
                    }}
                  >
                    지금 의견 보내기
                  </Button>
                  <Button
                    type="brown-light"
                    onClick={() => {
                      setGlobalVar({ already_alerted_send_button: true });
                      setGlobalData({ popup_data: null });
                    }}
                  >
                    네 알겠어요.
                  </Button>
                </List>
              </List>
            </div>
          ) : null}
        </Popup>
      )}
    </div>
  );
};

export default Header;
